/* eslint-disable */

import logo from './logo.svg';
import './App.css';
import { eth_abi } from './Eth_abi.js';
import { sepolia_abi } from './Sepolia_abi.js';
//import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
//import { useWeb3Modal } from '@web3modal/ethers/react';
//import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, TransactionReceipt, ethers, formatUnits} from 'ethers';
import { useState,useEffect } from 'react';
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useWeb3ModalState  } from '@web3modal/ethers/react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { utils } from 'ethers';

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_PROJECT_ID;

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: process.env.REACT_APP_ETH_RPC
  
}

// const sepolia = {
//   chainId: 11155111, 
//   name: 'Sepolia Chain',
//   currency: 'ETH', 
//   explorerUrl: 'https://sepolia.etherscan.io', 
//   rpcUrl: process.env.REACT_APP_SEPOLIA_RPC 
// }

// 3. Create modal
const metadata = {
  name: 'Immutable Incas',
  description: 'IM description',
  url: 'https://immutableincas.com/',
  icons: ['https://immutableincas.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ 
    metadata,
    defaultChainId: 1,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: '...' // used for the Coinbase SDK
  }),
  chains: [mainnet],
  projectId
})

function safeParseInt(str) {
  const value = parseInt(str, 10); // Always specify radix as 10 for decimal
  return isNaN(value) ? 0 : value;
}

// Utility function to update and verify the displayed quantity
function updateAndVerifyQuantity(element, newQuantity) {
  // Update the displayed quantity
  element.textContent = newQuantity;

  // Verify if the update was successful
  if (safeParseInt(element.textContent) !== newQuantity) {
      console.error("Failed to update the quantity display.");
      return false;
  }

  return true;
}

// Function to decrease quantity
function decreaseQuantity() {
const quantityElement = document.getElementById("quantity");
let currentQuantity = safeParseInt(quantityElement.textContent);

if (currentQuantity > 1) {
    const newQuantity = currentQuantity - 1;
    if (!updateAndVerifyQuantity(quantityElement, newQuantity)) {
        alert("Error in decreasing quantity. Please try again.");
    }
}

// If the updated quantity is less than 5, enable the increase button
if (currentQuantity < 6) {
    document.getElementById("increase-button").disabled = false;
}
}

// Function to increase quantity
function increaseQuantity() {
const quantityElement = document.getElementById("quantity");
let currentQuantity = safeParseInt(quantityElement.textContent);

if (currentQuantity < 5) {
    const newQuantity = currentQuantity + 1;
    if (!updateAndVerifyQuantity(quantityElement, newQuantity)) {
    alert("Error in increasing quantity. Please try again.");
    }
    
    // Check if new quantity is 5 to disable the button
    if (newQuantity === 5) {
        document.getElementById("increase-button").disabled = true;
    }
}
}

window.closePopup = function () {
  console.log("Closing popup");
  const popup = document.getElementById("popup");
  popup.style.display = "none"; // Hide the popup

  // Remove blur overlay
  const blurOverlay = document.querySelector('.blur-overlay');
  blurOverlay.classList.remove('overlay-visible');
};

function App() {
  const { address, isConnected} = useWeb3ModalAccount();
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isConnectedWallet, setIsConnectedWallet] = useState(false); // State variable to track wallet connection status
  const [errorMessage, setErrorMessage] = useState(""); 
  const { walletProvider } = useWeb3ModalProvider()
  const { selectedNetworkId } = useWeb3ModalState() 
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    setIsConnectedWallet(isConnected); // Update the wallet connection status when it changes
  }, [isConnected]);

  useEffect(() => {
    // Add logic to enable/disable Connect button based on wallet connection and popup status
    const connectButton = document.getElementById("connectWalletButton");
    if (connectButton) {
      connectButton.disabled = isConnectedWallet || isPopupOpen;
    }
  }, [isConnectedWallet, isPopupOpen]);

  const mintNFT = async () => { 

    twq('event','ohupm');
    try {
      const provider = new BrowserProvider(walletProvider)
      console.log("walletprovider:", walletProvider)
      console.log("provider:", provider)
      const signer = await provider.getSigner()
      const signature = await signer.signMessage('Hello, Welcome to Immutable Incas!\nThis Request will not trigger any blockchain transation or cost any gas fees!')
      
      console.log("signature:", signature);
      console.log("isConnected:", isConnected);
      console.log("walletProvider:", walletProvider);

      let USDTAddress, USDTAbi;
      if(selectedNetworkId === 1) {
        USDTAddress = process.env.REACT_APP_ETH_USDT_ADDRESS
        USDTAbi = eth_abi
      } 
      // else if(selectedNetworkId === 11155111) {
      //   USDTAddress = process.env.REACT_APP_SEPOLIA_USDT_ADDRESS
      //   USDTAbi = sepolia_abi
      //   setErrorMessage("Please switch to Ethereum Mainnet");
      //   setTimeout(() => {
      //     setErrorMessage(null);
      //   }, 10000);
      // } 
      else {
        setErrorMessage("Please switch to Ethereum Mainnet");
        setTimeout(() => {
          setErrorMessage(null);
        }, 10000);
        }
      
      

      console.log("USDTAddress:", USDTAddress);
      const usdtContract = new ethers.Contract(USDTAddress, USDTAbi, signer);
      

      const quantityElement = document.getElementById("quantity");
      const mintButton = document.getElementById("mintButton");

      const quantity = parseInt(quantityElement.textContent);
      console.log("quantity:", quantity);
      
      const priceWeiFromContract = await usdtContract.price();
      console.log("priceWeiFromContract:", priceWeiFromContract);
      const price = ethers.formatUnits(priceWeiFromContract, 'ether');
      console.log("price:", price);

    if (quantity <= 0) {
      throw new Error("Invalid quantity");
    }

    const priceInWei = ethers.parseUnits(price, 'ether');
    console.log("priceInWei:", priceInWei);
    const totalCostInWei = priceInWei*(BigInt(quantity));
    console.log("totalCostInWei:", totalCostInWei);

    const balanceWei = await provider.getBalance(address);
    console.log("balanceWei:", balanceWei);

    if (totalCostInWei > balanceWei) {
      //throw new Error("Insufficient funds");
      setErrorMessage("Insufficient funds. Please top up some Eth :)");
      setTimeout(() => {
        setErrorMessage(null);
      }, 10000);
    }
    
    const estimatedGas = await provider.estimateGas({to: usdtContract.address, value: totalCostInWei, from: address});
    console.log("providerestimatedGas:", estimatedGas);

    const multiplier = BigInt(Math.floor(1.5 * 100));
    const gasLimit = (BigInt(estimatedGas) * multiplier) / BigInt(100);
    console.log("gasLimit:", gasLimit);

    const gp = await provider.getFeeData();
    console.log("gasPrice:", gp.gasPrice);
    console.log("maxFeePerGas:", gp.maxFeePerGas);
    console.log("maxPriorityFeePerGas:", gp.maxPriorityFeePerGas);

    if (totalCostInWei < balanceWei) {
      console.log("Transaction cost is less than the user's balance. Proceed with the transaction.");
      //document.getElementById("mintButton").disabled = true;
      setButtonDisabled(true);

      const totalCostInWeiString = ethers.formatUnits(totalCostInWei, 'wei');
      
      console.log("totalCostInWeiString:",totalCostInWeiString);

      const result = await usdtContract.mintSale(quantity, ({ //gasPrice: ethers.parseUnits('200', 'gwei'),     
        from: address,
        gas: gasLimit,
        maxFeePerGas: gp.maxFeePerGas,
        maxPriorityFeePerGas: gp.maxPriorityFeePerGas,
        value: totalCostInWei.toString()
      }));

      const transactionReceipt = await provider.waitForTransaction(result.hash);
      console.log("transactionReceipt:", transactionReceipt);
    
      if (transactionReceipt.status === 1) {
        console.log("Transaction successful.");
        openPopup(transactionReceipt.hash, quantity);

      } else {
        throw new Error("Transaction failed. Receipt status: " + transactionReceipt.status);
      }
      
    } else {
      setErrorMessage("Less Money");
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }

    
  } catch (error) {
    console.error("Error minting NFTs:", error);
    setError("Error minting NFTs. Please try again.");
      setTimeout(() => {
        setError(null);
      }, 5000);
     
    } finally {
      setButtonDisabled(false);
    }
  };

  const increaseQuantity = () => {
    if (quantity < 5) {
    setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

   // Function to open the popup
   function openPopup(transactionHash, nftsMinted) {
    console.log("Opening popup with transactionHash:", transactionHash, "and nftsMinted:", nftsMinted);
    const popup = document.getElementById("popup");
    const transactionHashElement = document.getElementById("popup-transaction-hash");
    const nftsMintedElement = document.getElementById("popup-nfts-minted");
    const closeBtn = document.getElementById("close-button");
    
  
   if (popup && transactionHashElement && nftsMintedElement) {
        transactionHashElement.textContent = `Transaction ID - ${transactionHash}`;
        nftsMintedElement.textContent = `NFTs Minted - ${nftsMinted}`;
        popup.style.display = "block"; 
         console.log("visible or not");// Make the popup visible
   }
    
  else {
        console.error("Required elements not found in the DOM.");
    }
    
     // Add blur overlay
    const blurOverlay = document.querySelector('.blur-overlay');
    blurOverlay.classList.add('overlay-visible');

    setIsPopupOpen(true);
    };

  // Function to close the popup
  function closePopup() {
     console.log("Closing popup");
    const popup = document.getElementById("popup");
    popup.style.display = "none"; // Hide the popup
    
     // Remove blur overlay
    const blurOverlay = document.querySelector('.blur-overlay');
    blurOverlay.classList.remove('overlay-visible');

    setIsPopupOpen(false);
  };

  return (
    <div className="App">
      {/* ... Your existing JSX */}
      {errorMessage && <p>{errorMessage}</p>}
      <div className="quantity-control">
      <div className="quantity-buttons">
        <button id="decrease-button" className="quantity-button" onClick={decreaseQuantity}>
          -
        </button>
        <button id="quantity" className="quantity-button">
          {quantity}
        </button>
        <button id="increase-button" className="quantity-button increase-button" onClick={increaseQuantity}>
          +
        </button>
        </div>
        <button id="mintButton" className="btn btn-success" onClick={mintNFT} disabled={!isConnectedWallet || isButtonDisabled}>
          Summon Incas
        </button>
      </div>
     
      
    </div>

    
  );
};

export default App;
